<template lang="pug">
  main.main
    header-block
    banner-block
    advantages-block
    benefit-block
    use-block
    footer-block
</template>

<script>

export default {
  name: 'HomePage',
  components: {
    HeaderBlock: () => import('@/components/HeaderBlock'),
    BannerBlock: () => import('@/components/BannerBlock'),
    AdvantagesBlock: () => import('@/components/AdvantagesBlock'),
    BenefitBlock: () => import('@/components/BenefitBlock'),
    UseBlock: () => import('@/components/UseBlock'),
    FooterBlock: () => import('@/components/FooterBlock')
  }
}
</script>
